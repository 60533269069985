import React, { useState, useEffect, ReactElement, MouseEventHandler } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { selectedState, PunkView, myPunks } from '../../state'


interface Props {
  punkName: string,
  mintedState: boolean
}

function Punk({ punkName, mintedState }: Props) {
  const [selected, setSelected] = useRecoilState<string | null>(selectedState)
  const mine = useRecoilValue<PunkView>(myPunks)

  const handleClick = (e: any) => {
    setSelected(e.target.id)
  }

  const setStyle = (): any => {
    if (mine.show && punkName === selected) {
      return selectedBackground
    } else if (mine.show) {
      return mineBackground
    } else if (mintedState && punkName === selected) {
      return selectedAndMintedBg
    } else if (mintedState) {
      return mintedBackground
    } else if (punkName === selected) {
      return selectedBackground
    } else {
      return {}
    }
  }

  return (
    <div onClick={handleClick} >
      <img id={punkName} className='punk-img' src={punkName} style={setStyle()}/>
    </div>
  )
}

export default Punk


const selectedBackground = {
  backgroundColor: "rgba(255, 255, 0, 0.9)",
}

const mintedBackground = {
  backgroundColor: "rgba(255, 0, 0, 0.9)"
}

const selectedAndMintedBg = {
  backgroundColor: "rgba(255, 100, 200, 0.9 )"
}

const mineBackground = {
  backgroundColor: "rgba(0, 200, 0, 0.8 )"
}