import React from 'react'
import { useRecoilValue } from 'recoil'
import { windowDims, windowSize, scrollState } from '../state'

interface aboutScroll {
  fontSize: string;
  transform: string;
  opacity: string;
}

function About() {
  const scroll = useRecoilValue(scrollState)
  const windowShape = useRecoilValue<windowDims>(windowSize)

  const buildStyle = (row: number): aboutScroll => {
    let thresh = windowShape.height / 2 // baseline before slides
    let adjthresh = 10 * row**2 + thresh

    return {
      fontSize: "23px",
      transform: scroll < adjthresh ? `` : `translateX(${(scroll-adjthresh) * -1.5}px)`,
      // opacity: `1/${(scroll / 100) + 1}`
      opacity: scroll < adjthresh ? `1` : `${1 / ((scroll-adjthresh)**1.4 / 100 + 1)}`
    }
  }

  return (
    <div className='text-right pl-3 pt-3' id="AboutArea">
      <h5 className='desc-text' style={buildStyle(0)}>Mint price = 0.01Ξ</h5>
      <h5 className='desc-text mt-2' style={buildStyle(1)}>Only on Arbitrum</h5>
      <h5 className='desc-text mt-2' style={buildStyle(2)}>40Ξ to PadawanDao</h5>
      <h5 className='desc-text mt-2' style={buildStyle(3)}>20Ξ total awarded via lottery</h5>
    </div>
  )
}

export default About
