import React, { useState, useEffect, CSSProperties } from 'react'
import headerImg from '../assets/header_img.png'
import DescriptionText from './minis/DescriptionText'
import { useRecoilState } from 'recoil'
import { windowDims, windowSize, scrollState } from '../state'



function Description() {
  const [windowShape, setWindowShape] = useRecoilState<windowDims>(windowSize)
  const [scroll, setScroll] = useRecoilState<number>(scrollState)

  const setMarginTop = (): number => {
    if (windowShape.height < 800) {
      return 10
    } else {
      return (windowShape.height * 0.15)
    }
  }

  const style: CSSProperties = {
    // position: "relative",
    margin: "auto",
    marginTop: setMarginTop()
  }

  useEffect(() => {

    const handleScroll = () => {setScroll(window.scrollY)}
    handleScroll()
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  return (
    <div className="d-flex flex-wrap" style={style}>
      <div className="flex-fill mt-4">
        <div className="text-center">
          <h1 className="desc-text">PUNK Inception</h1>
          <h2 className="desc-sub-text">comes to life</h2>
          <h1 className="desc-sub-text sub-arb mt-0" style={{color: "white"}}>on  <span className="arbitrum-title">Arbitrum.</span></h1>
        </div>
      </div>
      <div className="flex-fill mt-2 image-box">
        <img className="desc-image" src={headerImg} style={{margin: "auto"}} />
      </div>
      <DescriptionText />
    </div>
  )
}

export default Description

// {margin: "auto", marginTop: "100px"}