import React, { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'

import Web3 from 'web3'
import WalletConnectProvider from "@walletconnect/web3-provider";
import walletconnect from '../assets/walletconnect.svg'

import mmLogo from '../assets/mmLogo.svg'
import { Modal, Button } from 'react-bootstrap'
import { NET_NAME, NETWORK, CONTRACT_ADDRESS, CONTRACT_ABI  } from '../config'
import { walletAccount, walletData } from '../state'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


type Props = {
  show: boolean,
  setShow: any,
}

// Adds ethereum property to window.
declare global {
  interface Window {
     ethereum: any;
  }
}


function WalletConnect({show, setShow}: Props) {
  const [account, setAccount] = useRecoilState<walletData | null>(walletAccount)
  // const [provider, setProvider] =useState<any>(null)


  const handleClose = () => {
    setShow(false)
  }

  // Connects via wallet connect.
  const walletConnect = async() => {
        //  Create WalletConnect Provider
    const provider: any = new WalletConnectProvider({
      rpc: {
        42161: 'https://arb-mainnet.g.alchemy.com/v2/81TmAPfkUn5KgWkoABjq43dsSNpZoy35' //TODO: move to .env
      }
    });

    try {
      await provider.enable();
      // setProvider(provider)
      const web3 = new Web3(provider);
      const accounts = await web3.eth.getAccounts();
      const accountData: walletData = {
        account: accounts[0],
        provider: "wc",
        connected: true
      }
      setAccount(accountData)
      setShow(false)
      toast.success("Wallet connected!")
    } catch {
      console.log("error")
    }
  }

  // Connects via metamask.
  const metamaskConnect = async() => {
    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum)
        // console.log(await web3.eth.net.getId())
        const netID = await web3.eth.net.getId()
        if (netID === NETWORK) {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
          const accountData: walletData = {
            account: accounts[0],
            provider: "mm",
            connected: true
          }
          setAccount(accountData)
          setShow(false)
          toast.success("Wallet connected!")
        } else {
            // check if the chain to connect to is installed
          toast.info("Please change network!")
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0xA4B1' }], // chainId must be in hexadecimal numbers
          });
        }
      } catch(error: any) {
        console.log(error)
      }
    } else {
      toast.info("Metamask not found")
    }
  }

  const updateWallet = async() => {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
    const accountData: walletData = {
      account: accounts[0],
      provider: "mm",
      connected: true
    }
    setAccount(accountData)
  }


  // Disconnects wallet connect storage info.
  const clearWalletConnect = () => {
    localStorage.removeItem("walletconnect")
  }

  useEffect((): any => {
    // Changed if user changes metamask account.
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', updateWallet)
      return (async() => {
        window.ethereum.removeListener('accountsChanged', updateWallet);
      })
    } else if (account?.provider === "wc") {
      // clearWalletConnect()
    } else {
      console.log("no default provider")
    }
  }, [account])


  return (
    <Modal show={show}>
      <Modal.Header closeButton>
        <Modal.Title>Connect Wallet</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex flex-row justify-content-between p-2" id="wallets">
          <img src={mmLogo} onClick={metamaskConnect}/>
          <img style={{width: "180px", height: "auto"}} src={walletconnect} onClick={walletConnect} />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleClose()}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default WalletConnect
