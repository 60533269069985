import { atom } from 'recoil'


const getWindowDimensions = ():windowDims => {
  const { innerWidth: width, innerHeight: height } = window
  return {width, height}
}

export interface windowDims {
  width: number,
  height: number
}

export const windowSize = atom<windowDims>({
  key: "windowSize",
  default: getWindowDimensions()
})

export const scrollState = atom<number>({
  key: "scrollState",
  default: 0
})


const randomPunk = (): number => {
  let min = Math.ceil(10000);
  let max = Math.floor(0);
  let rand = Math.floor(Math.random() * (max - min + 1) + min)
  return rand
}

export const selectedState = atom<string | null>({
  key: "selectedState",
  default: `./gen2/punk${randomPunk()}.png`
})

export interface walletData {
  account: any,
  provider: string,
  connected: boolean
}

export const walletAccount = atom<walletData | null>({
  key: "walletAccount",
  default: null
})

export interface mintedTye {
  minted: number[]
}

export const alreadyMinted = atom<number[]>({
  key: "alreadyMinted",
  default: []
})

export interface PunkView {
  ids: number[],
  show: boolean
}

export const myPunks = atom<PunkView>({
  key: "myPunks",
  default: {
    ids: [],
    show: false
  }
})