import React from 'react'
import banner from '../../assets/pnpbanner.png'
import { FiTwitter } from 'react-icons/fi'
import discordLogo from '../../assets/discordLogo.png'
import { useRecoilValue } from 'recoil'
import NFTemblem from './NFTemblem'
import { windowDims, windowSize, scrollState } from '../../state'


function MeetParents() {
  const scroll = useRecoilValue<number>(scrollState)
  const windowShape = useRecoilValue<windowDims>(windowSize)

  return (
    <div className='banner-box'
      style={{height: "400px"}}>
      <div className='d-flex flex-row justify-content-around'>
        <img className="banner" src={banner}></img>
        <img className="banner" src={banner}></img>
        <img className="banner" src={banner}></img>
        <img className="banner" src={banner}></img>
      </div>
      <footer>
        <h6 className='desc-text mt-2 text-center mb-4' style={{fontSize:"28px"}}>
          Have questions or want to learn more?
        </h6>
        <a className='ml-2' href='https://twitter.com/punknpunk'>
          <FiTwitter size={"2em"} color={"#1DA1F2"}/>
          <span className='desc-text ml-2' style={{fontSize:"16px"}}>@punknpunk</span>
        </a>
        <a className='ml-2 mt-3 d-flex flex-row' href="https://www.nftalliance.xyz/">
          <NFTemblem />
          <span className='desc-text ml-2 mt-0  ' style={{fontSize:"16px"}}>@Arbitrum NFT Alliance</span>
        </a>
        <div className='ml-2 mt-3'>
          <a href='https://discord.gg/4uwH4fKf'>
            <img className="discord-img" src={discordLogo}/>
            <span className='desc-text ml-2' style={{fontSize:"16px"}}>@NFT Alliance Discord</span>
          </a>
        </div>
      </footer>
    </div>
  )
}

export default MeetParents
