import React, { useState, useEffect, useRef, CSSProperties, Ref } from 'react'
import Description from './modules/Description'
import Mint from './modules/Mint'
import MeetParents from './modules/minis/MeetParents'
import TimeToPayout from './modules/TimeToPayout'
import SelectedView from './modules/minis/SelectedView'
import Collection from './modules/Collection'
import Rocket from './modules/Rocket'
import NFTemblem from './modules/minis/NFTemblem'
import { useRecoilValue } from 'recoil'
import { windowDims, windowSize, scrollState } from './state'



function Body() {
  const scroll = useRecoilValue<number>(scrollState)
  const windowShape = useRecoilValue<windowDims>(windowSize)


  const calcTopMargin = (): number => {
    let desc = windowShape.height * 0.85
    let mt = desc - 660
    if (mt < 15) {
      return 15
    } else if (mt > 600) {
      return 600
    } else {
      return mt
    }
  }

  const style: CSSProperties = {
    marginTop: `${calcTopMargin()}px`,
    transform: `translateY(${scroll * 0.2}px)`
  }




  return (
    <div>
      <Description />
      <div style={style} id="MintArea">
        <Mint />
        <Collection />
        <SelectedView />
      </div>
      <Rocket />
      <MeetParents />
      {/* <TimeToPayout /> */}
    </div>
  )
}

export default Body
