import React, { useState, ReactElement, useEffect } from 'react'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import Punk from './minis/Punk'
import banner from '../assets/bannershort.png'
import { selectedState } from '../state'
import { walletAccount, walletData, alreadyMinted, myPunks, PunkView } from '../state'
import { loadContract } from '../web3Utils/contract'
import { toast } from 'react-toastify'


const renderCount = 150

function Collection() {
  const [currentRender, setRender] = useState(0)
  const [selected, setSelected] = useRecoilState<string | null>(selectedState)
  const wallet = useRecoilValue<walletData | null>(walletAccount)
  const [minted, setMinted] = useRecoilState<number[]>(alreadyMinted)
  const [search, setSearch] = useState<number>(0)
  const mine = useRecoilValue<PunkView>(myPunks)



  const handleForward = () => {
    let newCurrent: number = currentRender + renderCount
    if (newCurrent >= 10000) {
      setRender(10000 - renderCount)
    } else {
      setRender(newCurrent)
    }
  }

  const handleBackward = () => {
    let newCurrent: number = currentRender - renderCount
    if (newCurrent < 0) {
      setRender(0)
    } else {
      setRender(newCurrent)
    }
  }

  const makePunk = (punkId: number): ReactElement => {
    let mintedState = false
    if (minted.includes(punkId)) {
      mintedState = true
    }
    return(<Punk punkName={`./gen2/punk${punkId}.png`} mintedState={mintedState}/>)
  }

  const buildPunkBox = (): ReactElement => {
    const renderPunkIds: number[] = []
    for (let i=currentRender; i<(currentRender + renderCount); i++) {
      renderPunkIds.push(i)
    }

    return (
      <div className='punk-collection-inside d-flex flex-row flex-wrap overflow-auto'>
        {renderPunkIds.map((n: number): any  => makePunk(n) )}
      </div>
    )
  }

  const buildMineBox = (): ReactElement => {
    // console.log(mine.ids)
    return (
      <div className='d-flex flex-row flex-wrap overflow-auto'>
        {mine.ids.map((n: number): any  => makePunk(n) )}
      </div>
    )
  }


  const getMinted = async() => {
    let contract = await loadContract(wallet?.provider, false)
    let mintedStr: string[] = await contract.methods.getMinted().call()
    let _minted = mintedStr.map(val => parseInt(val))
    setMinted(_minted)
    console.log(_minted)
  }

  useEffect(() => {
    if (wallet) {
      getMinted()
    }
  }, [currentRender, wallet])

  return (
    <div>
      <div className='d-flex flex-row justify-content-around banner-box mt-4'>
        <img className="banner-sm" src={banner}></img>
        <img className="banner-sm" src={banner}></img>
      </div>
      <div className='punk-collection'>
        {mine.show ? buildMineBox() : buildPunkBox()}
      </div>
      <div className='d-flex flex-row align-left'>
        <button
          className="btn btn-warning btn-lg mr-3 mt-3 mb-3"
          style={{marginLeft: "0px"}}
          id="collection-btn"
          onClick={handleBackward}>
          Back
        </button>
        <span
          className="btn btn-warning btn-lg m-3"
          id="collection-btn">
          {
            (currentRender + renderCount) > 10000 ?
            "1000-1000" :
            currentRender + "-" + (currentRender + renderCount)
          }
        </span>
        <button
          className="btn btn-warning btn-lg m-3"
          id="collection-btn"
          onClick={handleForward}>
          Forward
        </button>
      </div>
    </div>
  )
}

export default Collection
