import React, { useState } from 'react'
import WalletConnect from './WalletConnect'
import { useRecoilValue } from 'recoil'
import { walletAccount, walletData } from '../state'


function Header() {
  const account = useRecoilValue<walletData | null>(walletAccount)
  const [show, setShow] = useState<boolean>(false)

  const convertAccountString = (a: string): string => {
    let shortAdress = a[0]+a[1]+a[2]+a[3]+a[4]+a[5]+a[6] + "..." + a[38]+a[39]+a[40]+a[41]
    // console.log(shortAdress)
    return shortAdress
  }

  const connectButtonText = () => {
   if (account?.account) {
    let short = convertAccountString(account.account)
    return short
  } else {
    return "Web3 Connect"
   }
  }


  return (
      <nav className="navbar navbar-expand-sm sticky-top" id="custom-header">
        <div className="container-fluid">
          <a className="navbar-brand title" href="">Punk'n Punks</a>
            <button
              className="navbar-toggler btn btn-light btn-lg"
              style={{height: "35px"}}
              id="purple-btn"
              onClick={() => setShow(true)}>
              {connectButtonText()}
            </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-lg-0 pl-0 pr-2 justify-content-eavenly navbar-nav-scroll" style={{width: "100%"}}>
              <li className="nav-item m-2">
                <h3 className="nav-link title-link" onClick={() => {document.getElementById("MintArea")?.scrollIntoView({ behavior: 'smooth' })}}>Mint</h3>
              </li>
              <li className="nav-item m-2">
                <h3 className="nav-link active title-link" aria-current="page" onClick={() => document.getElementById("MintArea")?.scrollIntoView({ behavior: 'smooth'})}>About</h3>
              </li>
              <li className="nav-item m-2">
                <a className="nav-link title-link" href="https://arbiscan.io/address/0xd3d83E4A7332aDd51d0cdb82E9ffEfC310285DBa">Contact</a>
              </li>
            </ul>
            <span className="navbar-text ms-0 pl-3" style={{color: "white", width:"200px"}}>
              <button
                className="btn btn-light btn-lg"
                id="purple-btn"
                onClick={() => setShow(true)}>
                {connectButtonText()}
              </button>
            </span>
          </div>
        </div>
        <WalletConnect show={show} setShow={setShow}/>
      </nav>
  )
}


export default Header
