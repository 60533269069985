import React, { useState, useEffect, CSSProperties } from 'react'
import MintSelect from './minis/MintSelect'
import About from './About'
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { scrollState } from '../state'
import { walletAccount, walletData, alreadyMinted } from '../state'
import { loadContract } from '../web3Utils/contract'
import { toast } from 'react-toastify';

interface txntype {
  from: string,
  value: number,
  // gas: number
}

const baseMintPrice = 10000000000000000

function Mint() {
  const scroll = useRecoilValue(scrollState)
  const [minted, setMinted] = useRecoilState<number[]>(alreadyMinted)
  const [contract, setContract] = useState<any>()
  const wallet = useRecoilValue<walletData | null>(walletAccount)
  const [active, setActive] = useState<boolean>(false)


  const getNotMinted = async(_minted: number[]) => {
    let notMinted = allIds().filter(item => !_minted.includes(item))
    return notMinted
  }

  const genTxnOptions = (mintCount: number, account: string): txntype => {
    let value = mintCount * baseMintPrice
    let _gas = 5000000 + (2000000 * mintCount)
    return {
      from: account,
      value: value,
      // gas: _gas
    }
  }

  const mintToken = async(count: number) => {
    if (wallet) {
      setActive(true)
      try {
        let contract = await loadContract(wallet.provider, false)
        let mintedStr: string[] = await contract.methods.getMinted().call()
        let _minted = mintedStr.map(val => parseInt(val))
        setMinted(_minted)
        let available = await getNotMinted(_minted)
        let mintIds = getRandomIds(count, available)

        let txnOptions = genTxnOptions(count, wallet.account)
        await contract.methods.multiMintNFT(wallet.account, mintIds).send(txnOptions)

        toast.info(`Ids: ${mintIds}`, {autoClose: 20000})
        toast.success(`Minted ${count} PNPs 🚀🎊🎉!`, {autoClose: 20000})
      } catch (error: any){
        console.log(error)
        toast.warning('NFTs not minted 😔')
      }
      setActive(false)
    } else {
      toast.warn("Connect wallet first!")
    }
  }

  const style: CSSProperties = {
    // position: "relative",
    margin: "auto",
    // top: "12px",
    transform:  `translateY(${scroll * -0.05 + 15}px)`,
    marginTop: "30px"
  }


  return (
    <div className="d-flex flex-wrap-reverse justify-space-between"
      style={style}>
      <div className="flex-fill mt-1" style={{textAlign: "center", width:"50%"}}>
        <About />
      </div>
      <div className="flex-fill mt-1" style={{textAlign: "center", width:"50%"}}>
        <MintSelect mintToken={mintToken} active={active}/>
      </div>
  </div>
  )
}

export default Mint


const allIds = (): number[] => {
  let idRange: number[] = []
  for (let i=0; i < 10000; i ++) {
    idRange.push(i)
  }
  return idRange
}

const getRandomIds = (n: number, arr: number[]) => {
  let randIds: number[] = []
  let newar = arr
  for (let i=0; i<n; i++) {
    let index = Math.floor(Math.random() * newar.length)
    let punkId = newar[index]
    randIds.push(punkId)
    newar.splice(index, 1)
  }
  return randIds
}