import Web3 from 'web3'
import WalletConnectProvider from "@walletconnect/web3-provider";
import { CONTRACT_ADDRESS, CONTRACT_ABI, PNP_CONTRACT_ABI, PNP_CONTRACT_ADDRESS } from '../config'

export const loadContract = async(provider: string | undefined, token: boolean) => {
  // Set web3
  let web3: any
  if (provider === "wc") {
    const provider: any = new WalletConnectProvider({
      rpc: {
        42161: 'https://arb-mainnet.g.alchemy.com/v2/81TmAPfkUn5KgWkoABjq43dsSNpZoy35' //TODO: move to .env
      }
    });
    await provider.enable();
    // setProvider(provider)
    web3 = new Web3(provider);
  } else if (provider === "mm"){
    web3 = new Web3(window.ethereum)
  }

  // Load contract
  let contract: any
  if (token) {
    contract = new web3.eth.Contract(PNP_CONTRACT_ABI as [], PNP_CONTRACT_ADDRESS)
  } else {
    contract = new web3.eth.Contract(CONTRACT_ABI as [], CONTRACT_ADDRESS)
  }

  return contract
}