import { useEffect, useState } from 'react';
import './App.css';
import Header from "./modules/Header"
import Body from "./Body"
import { RecoilRoot } from 'recoil';
import { ToastContainer } from 'react-toastify';



function App() {

  return (
    <RecoilRoot>
      <div className="body-wrap">
        <Header />
        <Body />
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
      </div>
    </RecoilRoot>
  );
}

export default App;


