import React, {ChangeEventHandler, useState} from 'react'
import MyViews from './MyViews'
import { Spinner } from 'react-bootstrap'
import { act } from 'react-dom/test-utils'


type Props = {
  mintToken: any,
  active: boolean
}

function MintSelect({ mintToken, active }: Props) {
  const [count, setCount] = useState<number>(1)

  const updateCount = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCount(parseInt(e.target.value))
  }

  return (
    <div className="">

        <div style={{position: "relative"}}>
          <button
            className="btn btn-warning btn-lg mt-3"
            id="mint-button"
            onClick={() => mintToken(count)}>
            Mint
          </button>
          {active && <div className='mine-loading' style={{left: "300px", top: "35px"}}><Spinner animation="border" variant="light" /></div>}
        </div>

        <div className='mt-2 align-content-center'>
          <span className='count-label pr-2' style={{color:"white"}}>Count:</span>
          <input className="" type="number" style={{width: "70px"}} value={count} onChange={updateCount}/>
        </div>

        <MyViews />

    </div>
  )
}

export default MintSelect
