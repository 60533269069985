import React, { CSSProperties } from 'react'
import rocket from '../assets/rocket.gif'
import { useRecoilValue } from 'recoil'
import { windowDims, windowSize, scrollState } from '../state'

function Rocket() {
  const scroll = useRecoilValue<number>(scrollState)
  const windowShape = useRecoilValue<windowDims>(windowSize)

  const gifStyle: CSSProperties = {
    position: "relative",
    transform: `rotate(180deg) translateY(${scroll * -0.5 + 350}px)`,
    width: "300px",
    height: "300px",
    left: "-90px",
    zIndex: "+1"
  }

  const textStyle: CSSProperties = {
    fontSize: "26px",
    position: "relative",
    top: "-175px",
  }


  const buildStyle = (row: number): any => {
    let thresh = windowShape.height / 2 // baseline before slides
    let adjthresh = 1 * row**2 + thresh

    return {
      padding: "15px",
      maxWidth: "620px",
      alignSelf: "right",
      marginLeft: "auto",
      marginRight: "10px",
      // opacity: `1/${(scroll / 100) + 1}`
      opacity: scroll < adjthresh ? `0` : `${(scroll-adjthresh)/1000}`
    }
  }
  const box: CSSProperties = {
    // borderRadius: "0.1rem",
    // borderRight: "#ff00ff 0.001em solid",
    // borderBottom: "#ff00ff 0.001em solid",
    // boxShadow: "5px 0px 3px #ff00ff",
    padding: "15px",
    maxWidth: "620px",
    alignSelf: "right",
    marginLeft: "auto",
    marginRight: "10px"
  }

  const white: CSSProperties = {
    color: "white"
  }



  return (
    <div id="Rocket">
      <img src={rocket} style={gifStyle}/>
      <div className='payout-text text-right' style={textStyle}>
        <ul className="about-list">
          <li style={buildStyle(8)}>Every time YOU mint enters you into the PNP Lottery with the chance to win <span style={white}>0.4Ξ</span>.</li>
          <li style={buildStyle(9)}>Each mint will increase your odds of winning. Lottery ends every <span style={white}>200 mints</span></li>
          <li style={buildStyle(10)}>i.e. with 20 mints you'd have a <span style={white}>10%</span> chance to win 0.4Ξ</li>
          <li style={buildStyle(11)}>Majority of the funds go back to you the <span style={white}>minters</span> and <span style={white}>@PadawanDao</span></li>
          <li style={buildStyle(12)}>PadawanDao is training the next generation of <span style={white}>crypto Jedis</span> and guides them into the cryptoverse 👉 <a href='https://mobile.twitter.com/padawandao'>check em out</a></li>

        </ul>
      </div>
    </div>
  )
}

export default Rocket


// transform: `translateY(${scroll * 0.5}px)