import React from 'react'

function NFTemblem() {
  return (
    <a className="nfta-emblem" title="NFT Alliance" href="https://www.nftalliance.xyz/" target="_blank" rel="noopener noreferrer">
      <svg className="nfta-logo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 108 112">
        <g clipPath="url(#a)">
          <path fill="#000910" d="M53.6 109c-.9 0-1.8 0-2.7-.1V2.6c.9 0 1.8-.1 2.7-.1.9 0 1.8 0 2.7.1V109h-2.7Z" />
          <path fill="#000" d="M53.6 5h.2v101.5h-.4V5h.2Zm0-5c-1.8 0-3.5.1-5.2.2v111c1.7.2 3.5.2 5.2.2 1.7 0 3.5-.1 5.2-.2V.2C57.1.1 55.4 0 53.6 0Z" />
          <path fill="#000910" d="m80.6 55.7001 21.6-21.6c.8 1.8 1.5 3.7 2.1 5.6l-16 16 16 16c-.6 1.9-1.3 3.8-2.1 5.6l-21.6-21.6Z" />
          <path fill="#000" d="M101.4 38.5999c.1.2.1.3.2.5l-13.2 13.1-3.5 3.5 3.5 3.5 13.2 13.2c-.1.2-.1.3-.2.5l-13.4-13.3-3.9-3.9 17.3-17.1Zm1.6-8.7-25.9 25.9 7.4 7.4 18.5 18.4c1.8-3.3 3.2-6.9 4.2-10.6l-15.3-15.2 15.3-15.3c-1-3.8-2.5-7.3-4.2-10.6Z" />
          <path fill="#000910" d="M27.9 102.4c-1.6-.9-3.2-1.9-4.8-2.9999l13.9-43.6-13.9-43.7c1.5-1.1 3.1-2.1 4.8-3l14.9 46.6L27.9 102.4Z" />
          <path fill="#000" d="m26.4 12.9 8.7 27.2 5 15.7-5 15.7-8.7 27.2c-.1-.1-.3-.2-.4-.3l13.1-41.1.5-1.5-.5-1.5L26 13.1c.2 0 .3-.1.4-.2Zm2.9-7.4C26.1 7.1 23 9 20.2 11.1l14.2 44.6-14.2 44.6c2.9 2.1 5.9 4 9.2 5.6l10.6-33 5.5-17.2L40 38.5l-10.7-33Z" />
          <path fill="#000910" d="m64.5 55.8001 14.9-46.7c1.6.9 3.2 1.9 4.8 3l-14 43.7 13.9 43.6C82.6 100.5 81 101.5 79.3 102.4L64.5 55.8001Z" />
          <path fill="#000" d="M80.8 12.9c.1.1.3.2.4.3l-13.1 41-.5 1.5.5 1.5 13.1 41.1c-.1.1-.3.2-.4.3l-8.7-27.2-5-15.7 5-15.7 8.7-27.1Zm-2.9-7.4-10.6 33-5.5 17.2 5.5 17.2 10.6 33c3.2-1.6 6.3-3.5 9.2-5.6L72.8 55.8 87 11.2c-2.8-2.2-5.9-4.1-9.1-5.7Z" />
          <path fill="#000910" d="M4.89999 77.3997c-.8-1.8-1.5-3.7-2.1-5.6l16.00001-16-16.00001-16c.6-1.9 1.3-3.8 2.1-5.6L26.5 55.7997l-21.60001 21.6Z" />
          <path fill="#000" d="m5.8 38.5999 17.2 17.1-3.9 3.9-13.3 13.3c-.1-.2-.1-.3-.2-.5l13.2-13.2 3.5-3.5-3.5-3.5-13.1-13.2c0-.1.1-.3.1-.4Zm-1.6-8.7c-1.7 3.3-3.1 6.8-4.2 10.5l15.3 15.3-15.3 15.4c1.1 3.7 2.5 7.2 4.2 10.6l18.5-18.5 7.4-7.4-25.9-25.9Z" />
        </g>
      </svg>
    </a>
  )
}

export default NFTemblem
