import React, { useState } from 'react'
import gif from '../../assets/punkpunk.gif'
import gif2 from '../../assets/punkpunk2.gif'
import { walletAccount, walletData, alreadyMinted, myPunks, PunkView } from '../../state'
import { loadContract } from '../../web3Utils/contract'
import { toast } from 'react-toastify'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Spinner } from 'react-bootstrap'

function MyViews() {
  const wallet = useRecoilValue<walletData | null>(walletAccount)
  const [minted, setMinted] = useRecoilState<number[]>(alreadyMinted)
  const [mine, setMine] = useRecoilState<PunkView>(myPunks)
  const [loading, setLoading] = useState<boolean>(false)

  const getMyPunks = async() => {
    if (wallet) {
      if (!mine.show) {
        setLoading(true)
        let contract = await loadContract(wallet?.provider, true)
        let mintedStr: string[] = await contract.methods.mintedTokenIds().call()
        let _minted = mintedStr.map(val => parseInt(val))
        setMinted(_minted)

        let myPunkIds: number[] = []
        for (let index in _minted) {
          let punkId = _minted[index]
          let punkOwner = await contract.methods.ownerOf(punkId).call()

          if (punkOwner.toUpperCase() === wallet.account.toUpperCase()) {
            myPunkIds.push(punkId)
          }
        }
        setLoading(false)
        setMine({
          ids: myPunkIds,
          show: true
        })
      } else {
        setMine({
          ids: mine.ids,
          show:false
        })
      }
    } else {
      toast.warning("Please connect wallet first.")
    }
  }


  return (
    <div className='mt-4'>
      <button
        className="btn btn-warning btn-lg m-3"
        id="view-btn"
        value="Mine"
        onClick={getMyPunks}
        style={{position: "relative"}}>
        {mine.show? "View Collection" : "View My PNPs"}
        {loading && <div className='mine-loading'><Spinner animation="border" size="sm" variant="light" /></div>}
      </button>
      <div className='view-box auto mt-2 collapse' style={{margin: "auto"}}>
        <div className="flex-fill mt-2" style={{textAlign: "center", minWidth:"50%"}}>
          <img className="punk-gif" src={gif} style={{margin: "auto"}} />
          <img className="punk-gif" src={gif2} style={{margin: "auto"}} />
        </div>
      </div>
    </div>
  )
}

export default MyViews
