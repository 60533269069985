import React, { useState, useEffect, CSSProperties } from 'react'
import { useRecoilValue } from 'recoil'
import { windowDims, windowSize } from '../../state'
import { scrollState, selectedState } from '../../state'




function TimeToPayout() {
  const windowShape = useRecoilValue<windowDims>(windowSize)
  const scroll = useRecoilValue(scrollState)
  const selected = useRecoilValue<string | null>(selectedState)


  return (
    <div className='count-down text-center' style={style}>
      {
        selected && <img src={selected} className='main-punk-img'/>
      }
      {
       selected && <h6 className='mt-2' style={text}>pnp#{nameToNumber(selected)}</h6>
      }
    </div>
  )
}

export default TimeToPayout


const nameToNumber = (name: string): number => {
  const numName = name.split("punk")[1] // Temp until name change.
  return parseInt(numName.split(".png")[0])
}

const style: CSSProperties = {
  position: "relative",
  // top: "200px",
  // top: `${(scroll * -0.2) + (0.65 * windowShape.height)}px`,
  // right: "calc(50% + 450px)",
  left: "-220px",
  top: "-300px"
}

const text: CSSProperties = {
  fontFamily: "'Ubuntu', sans-serif",
  fontSize: "32px",
  color: "#e7c4ff",
}