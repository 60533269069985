import React from 'react'

// All
const body = {
  fontFamily: "'Ubuntu', sans-serif",
  marginTop: "9px",
  color: "white",
  borderLeft: "white 2px solid",
  borderRight: "white 2px solid",
  marginLeft: "15px",
  marginRight: "15px",
}

// Description text
const style = {
  fontWeight: "300",
  fontSize: "16px",
  padding: "15px",
  paddingTop: "30px",
  marginLeft: "5px",
  marginRight: "5px"
}

function DescriptionText() {
  return (
    <div className='d-flex compress-animation' style={body}>
      <h5 className="text-left word-wrap" style={style}>
        After extreme famine in the metaverse, crypto punks resorted to canibalism in order to survive;
        this gave birth to a new breed of punks -<span className="arbitrum-title" style={{fontSize:"20px"}}>Punk'n Punks.</span>
      </h5>
    </div>
  )
}

export default DescriptionText
